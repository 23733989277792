<template>
  <div>
    <Auth v-if="isLoggedIn === true" />
    <Guest v-if="isLoggedIn === false" />

    <!-- Wait for ProgressSpinner fix in PrimeVue css! -->
    <!-- <div v-if="isAuth === null" class="text-center">
      <h1 class="text-2xl font-bold">Loading...</h1>
      <ProgressSpinner v-if="isAuth === null" />
    </div> -->
  </div>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";
import { watch, onMounted, ref } from "vue";
import { useAuth, InteractionStatus } from "@cumulus/event-bus";

import Guest from "./guest/GuestDashboard.vue";
import Auth from "./auth/AuthDashboard.vue";

const route = useRoute();
const { getUser, isAuthenticated, inProgress, accounts } = useAuth();

const { login, logout } = useAuth();

const isLoggedIn = ref<boolean | null>(null);

const authenticate = async () => {
  if (inProgress.value === InteractionStatus.None) {
    if (accounts.value.length > 0) {
      await getUser();

      if (route.query?.unauthenticated !== undefined) {
        await logout();
        return;
      }

      isLoggedIn.value = isAuthenticated.value;
      return;
    }

    if (route.query?.authenticated !== undefined) {
      await login();
      return;
    }

    if (route.name != undefined && route.name === "oauth2-redirect") {
      return;
    }

    isLoggedIn.value = isAuthenticated.value;
  }

  if (inProgress.value === InteractionStatus.Startup) {
    await getUser();

    isLoggedIn.value = isAuthenticated.value;
  }
};

watch(inProgress, authenticate);
onMounted(authenticate);
</script>
