{
  "dashboard": {
    "warnings": "Alerts (5)",
    "unfinished-orders": "Unfinished orders",
    "certifications": "Certifications",
    "add-order": "New order",
    "add-customer": "Add customer",
    "add-product": "Add product",
    "goods-receipt": "Goods receipt",
    "inventory": "Inventory",

    "settings": "Settings",
    "add-client": "Add client",
    "client-search": "Client search",
    "add-employee": "Add employee",
    "stock-search": "Stock search",
    "tips-from": "Tips fra Multicase",
    "read-more": "Read more"
  },

  "guest": {
    "title": "",
    "subtitle": "",
    "copyright": "Copyright © 2023 Multicase Norge AS"
  }
}
