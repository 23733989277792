<template>
  <div class="flex flex-wrap gap-5 mt-12 md:mt-2 lg:mt-4 mb-4">
    <Button
      class="c-dashboard-link flex grow justify-between items-center my-1 py-2 px-4 text-sky-500 bg-sky-200 border-sky-200 hover:text-sky-200 hover:bg-sky-500 dark:text-stone-700 dark:bg-stone-500 dark:border-stone-500 dark:hover:text-stone-300 dark:hover:bg-stone-700 rounded-lg shadow-md max-w-80"
      @click="route('/order/add')"
    >
      <div class="flex items-center">
        <div class="m-1 p-4">
          <i class="pi pi-shopping-cart text-4xl" />
        </div>
        <div class="pl-4 min-w-48 text-left text-sky-800 dark:text-stone-800 text-lg font-medium truncate">
          {{ t("dashboard.add-order") }}
        </div>
      </div>
      <div class="-ml-2" @click="onClickArrow($event, '/order/add')">
        <i class="pi pi-chevron-right" />
      </div>
    </Button>

    <Button
      class="c-dashboard-link flex grow justify-between items-center my-1 py-2 px-4 text-emerald-500 bg-emerald-200 border-emerald-200 hover:text-emerald-200 hover:bg-emerald-500 dark:text-stone-700 dark:bg-stone-500 dark:border-stone-500 dark:hover:text-stone-300 dark:hover:bg-stone-700 rounded-lg shadow-md md:min-w-min"
      @click="route('/customer/add')"
    >
      <div class="flex items-center">
        <div class="m-1 p-4">
          <i class="pi pi-user text-4xl" />
        </div>
        <div class="pl-4 min-w-48 text-left text-emerald-800 dark:text-stone-800 text-lg font-medium truncate">
          {{ t("dashboard.add-customer") }}
        </div>
      </div>
      <div @click="onClickArrow($event, '/customer/add')">
        <i class="pi pi-chevron-right" />
      </div>
    </Button>

    <Button
      class="c-dashboard-link flex grow justify-between items-center my-1 py-2 px-4 text-amber-400 bg-amber-100 border-amber-100 hover:text-amber-100 hover:bg-amber-400 dark:text-stone-700 dark:bg-stone-500 dark:border-stone-500 dark:hover:text-stone-300 dark:hover:bg-stone-700 rounded-lg shadow-md md:min-w-min"
      @click="route('/product/add')"
    >
      <div class="flex items-center">
        <div class="m-1 p-4">
          <i class="pi pi-clone text-4xl" />
        </div>
        <div class="pl-4 min-w-48 text-left text-amber-800 text-lg dark:text-stone-800 font-medium truncate">
          {{ t("dashboard.add-product") }}
        </div>
      </div>
      <div @click="onClickArrow($event, '/product/add')">
        <i class="pi pi-chevron-right" />
      </div>
    </Button>

    <Button
      class="c-dashboard-link flex grow justify-between items-center my-1 py-2 px-4 text-orange-400 bg-orange-200 border-orange-200 hover:text-orange-200 hover:bg-orange-400 dark:text-stone-700 dark:bg-stone-500 dark:border-stone-500 dark:hover:text-stone-300 dark:hover:bg-stone-700 rounded-lg shadow-md md:min-w-min"
      @click="route('/goods-reception/search')"
    >
      <div class="flex items-center">
        <div class="m-1 p-4">
          <i class="pi pi-sign-in text-4xl" />
        </div>
        <div class="pl-4 min-w-48 text-left text-orange-800 text-lg dark:text-stone-800 font-medium truncate">
          {{ t("dashboard.goods-receipt") }}
        </div>
      </div>
      <div @click="onClickArrow($event, '/goods-reception/search')">
        <i class="pi pi-chevron-right" />
      </div>
    </Button>

    <Button
      class="c-dashboard-link flex grow justify-between items-center my-1 py-2 px-4 text-rose-400 bg-rose-200 border-rose-200 hover:text-rose-200 hover:bg-rose-400 rounded-lg dark:text-stone-700 dark:bg-stone-500 dark:border-stone-500 dark:hover:text-stone-300 dark:hover:bg-stone-700 shadow-md md:min-w-min"
      @click="route(inventoryRoute)"
      data-testid="inventory-button"
    >
      <div class="flex items-center">
        <div class="m-1 p-4">
          <i class="pi pi-box text-4xl" />
        </div>
        <div class="pl-4 min-w-48 text-left text-rose-800 text-lg dark:text-stone-800 font-medium truncate">
          {{ t("dashboard.inventory") }}
        </div>
      </div>
      <div @click="onClickArrow($event, inventoryRoute)" data-testid="inventory-arrow">
        <i class="pi pi-chevron-right" />
      </div>
    </Button>
  </div>
</template>

<script setup lang="ts">
import router from "@/router/router";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const inventoryRoute = "/warehouse/inventory/search";
const route = (path: string) => {
  router.push(path);
};
const onClickArrow = (event: Event, path: string) => {
  event.stopPropagation();
  window.open(path, "_blank");
};
</script>
