<template>
  <div class="c-guest-dashboard overflow-hidden" data-testid="guest-dashboard">
    <div class="grid grid-cols-12 gap-4 flex justify-center">
      <div class="col-span-12 c-guest-center">
        <div class="c-content">
          <img alt="Dashboard image" src="@/assets/images/dashboard.jpg" class="c-main-img w-screen overflow-hidden" />
          <div class="c-image-text">
            <div class="text-3xl font-bold">{{ t("guest.title") }}</div>
            <p class="text-xl">{{ t("guest.subtitle") }}</p>
          </div>
        </div>
      </div>
      <div class="col-span-12 c-footer-guest">
        <div class="text-center">{{ t("guest.copyright") }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>

<style lang="scss" scoped>
.c-guest-dashboard {
  .c-content {
    overflow: hidden;
    position: relative;
    text-align: center;

    @media (min-width: 992px) {
      max-height: 1024px;
    }
  }
  .c-main-img {
    max-width: var(--screen-size-max);
  }
  .c-image-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20pt;
    color: #333;
  }

  .crop img {
    height: 50%;
    margin: -75px 0 0 0;
  }

  @media (max-width: 992px) {
    .c-image-text {
      top: 60%;
    }
  }

  .c-footer-guest {
    padding: 0;
  }
}
</style>
