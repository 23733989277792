<template>
  <Card class="max-w-80" pt:body:class="pt-0">
    <template #header>
      <div class="px-5 pt-5 pb-2 flex justify-between">
        <div class="flex items-center justify-center font-bold text-lg dark:text-stone-500">Varsel</div>
        <div class="flex items-center justify-center">
          <Badge value="5" class="dark:bg-stone-500"></Badge>
        </div>
      </div>
    </template>
    <template #content>
      <div class="flex m-0 md:m-1">
        <div>
          <div class="flex flex-wrap">
            <Card class="bg-green-200 dark:bg-stone-800 w-full mb-3" pt:body:class="pt-0">
              <template #header>
                <div class="px-5 pt-5 font-bold leading-5 dark:text-stone-400">Ikke avsluttet ordre</div>
              </template>
              <template #content>
                <div class="leading-5 dark:text-stone-400">Ukjent kunde</div>
                <div class="leading-5 dark:text-stone-400">Ordrenummer: 00125487</div>
                <Button
                  size="small"
                  label="Se ordre"
                  class="p-button-success mt-2 dark:bg-stone-400 dark:border-stone-500"
                  @click="noAction"
                >
                </Button>
              </template>
            </Card>

            <Card class="bg-green-50 dark:bg-stone-800 w-full mb-3" pt:body:class="pt-0">
              <template #header>
                <div class="px-5 pt-5 font-bold leading-5 dark:text-stone-500">Ikke avsluttet ordre</div>
              </template>
              <template #content>
                <div class="leading-5 dark:text-stone-400">Ukjent kunde</div>
              </template>
            </Card>

            <Card class="bg-green-50 dark:bg-stone-800 w-full mb-3" pt:body:class="pt-0">
              <template #header>
                <div class="px-5 pt-5 font-bold leading-5 dark:text-stone-400">Ikke avsluttet ordre</div>
              </template>
              <template #content>
                <div class="leading-5 dark:text-stone-400">Ukjent kunde</div>
              </template>
            </Card>

            <Card class="bg-amber-50 dark:bg-stone-800 w-full mb-3" pt:body:class="pt-0">
              <template #header>
                <div class="px-5 pt-5 font-bold leading-5 dark:text-stone-400">Ikke avsluttet ordre</div>
              </template>
              <template #content>
                <div class="leading-5 dark:text-stone-400">Ukjent kunde</div>
              </template>
            </Card>

            <Card class="bg-amber-50 dark:bg-stone-800 w-full mb-3" pt:body:class="pt-0">
              <template #header>
                <div class="px-5 pt-5 font-bold leading-5 dark:text-stone-400">Ikke avsluttet ordre</div>
              </template>
              <template #content>
                <div class="leading-5 dark:text-stone-400">Ukjent kunde</div>
              </template>
            </Card>

            <Card class="bg-red-50 dark:bg-stone-800 w-full mb-3" pt:body:class="pt-0">
              <template #header>
                <div class="px-5 pt-5 font-bold leading-5 dark:text-stone-500">Ikke avsluttet ordre</div>
              </template>
              <template #content>
                <div class="leading-5 dark:text-stone-400">Ukjent kunde</div>
              </template>
            </Card>

            <Card class="bg-red-50 dark:bg-stone-800 w-full mb-3" pt:body:class="pt-0">
              <template #header>
                <div class="px-5 pt-5 font-bold leading-5 dark:text-stone-500">Ikke avsluttet ordre</div>
              </template>
              <template #content>
                <div class="leading-5 dark:text-stone-400">Ukjent kunde</div>
              </template>
            </Card>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script setup lang="ts">
const noAction = () => {
  alert("Under construction");
};
</script>
