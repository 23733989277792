{
  "dashboard": {
    "warnings": "Varsler (5)",
    "unfinished-orders": "Ikke avsluttede ordre",
    "certifications": "Attesteringer",
    "add-order": "Ny ordre",
    "add-customer": "Legg til kunde",
    "add-product": "Legg til produkt",
    "goods-receipt": "Varemottak",
    "inventory": "Lagerbeholdning",

    "settings": "Innstillinger",
    "add-client": "Legg til klient",
    "client-search": "Klientsøk",
    "add-employee": "Legg til medarbeider",
    "stock-search": "Lagersøk",
    "tips-from": "Tips fra Multicase",
    "read-more": "Les mer"
  },

  "guest": {
    "title": "",
    "subtitle": "",
    "copyright": "Copyright © 2023 Multicase Norge AS"
  }
}
