<template>
  <Card class="mt-5 bg-slate-200 dark:bg-stone-700">
    <template #content>
      <div class="flex flex-wrap my-2">
        <div class="c-footer-tip flex grow m-1">
          <div>
            <Button
              :label="t('dashboard.tips-from')"
              class="p-button-text text-slate-900 dark:text-stone-300 text-lg"
            />
          </div>
        </div>

        <div class="c-footer-col flex grow">
          <div>
            <Button
              class="p-button-text underline font-medium pl-0 text-slate-900 dark:text-stone-400 hover:bg-transparent"
              >Slik oppretter du ny kunde</Button
            >
            <div class="c-footer-col-text mb-6 dark:text-stone-300">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictum elementum eget facilisi urna.
            </div>
          </div>
        </div>

        <div class="c-footer-col flex grow">
          <div>
            <Button
              class="p-button-text underline font-medium pl-0 text-slate-900 dark:text-stone-400 hover:bg-transparent"
              >Lage ordre</Button
            >
            <div class="c-footer-col-text mb-6 dark:text-stone-300">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </div>
          </div>
        </div>

        <div class="c-footer-col flex grow">
          <div>
            <Button
              class="p-button-text underline font-medium pl-0 text-slate-900 dark:text-stone-400 hover:bg-transparent"
              >Salgsstatistikk</Button
            >
            <div class="c-footer-col-text mb-6 dark:text-stone-300">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </div>
          </div>
        </div>

        <div class="c-footer-col flex grow">
          <div>
            <Button
              class="p-button-text underline font-medium pl-0 text-slate-900 dark:text-stone-400 hover:bg-transparent"
              >Massesletting av produkter</Button
            >
            <div class="c-footer-col-text mb-6 dark:text-stone-300">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </div>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<style lang="scss" scoped>
.c-footer-tip {
  width: 100%;
  font-weight: 500;

  @media (min-width: 992px) {
    max-width: 12rem;
    min-width: 12rem;
  }
  @media (min-width: 1230px) {
    max-width: 18.5rem;
    min-width: 18.5rem;
  }
}
.c-footer-col {
  width: 50%;
  padding: 0 1.5rem;
  &:last-child {
    border-right: none;
  }

  @media (min-width: 992px) {
    margin: 0.5rem;
    border-right: 1px solid var(--p-gray-300);
    max-width: 10rem;
    min-width: 10rem;
  }
  @media (min-width: 1230px) {
    max-width: 12rem;
    min-width: 12rem;
  }
  @media (min-width: 1500px) {
    max-width: 16rem;
    min-width: 16rem;
  }
  @media (min-width: 1780px) {
    max-width: 21.2rem;
    min-width: 21.2rem;
  }

  // .p-button-text {
  //   color: var(--c-blue-500);
  // }
  .c-footer-col-text {
    font-family: var(--font-family);
    font-size: 14px;
  }
}
</style>
