<template>
  <div class="c-layout-content">
    <PrimeVueLocaleHandler />
    <router-view v-slot="{ Component }">
      <component :is="Component" :key="$route.fullPath" />
    </router-view>
    <DevOverlay v-if="isStandalone && !isRunningTests" />
  </div>
</template>

<script setup lang="ts">
import { PrimeVueLocaleHandler } from "@cumulus/locale";
import { onMounted, ref } from "vue";
import { useAuth } from "@cumulus/event-bus";
import { DevOverlay } from "@cumulus/components";

const isStandalone = ref(import.meta.env.VITE_APP_STANDALONE === "true");
const isRunningTests = ref(import.meta.env.VITE_APP_TESTS === "true");

//Only running this code if the app is standalone
if (isStandalone.value) {
  const { getUser, isAuthenticated } = useAuth();
  onMounted(async () => (document.body.className = (await getUser()).getBodyCssClass(isAuthenticated.value)));
}
</script>
