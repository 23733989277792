<template>
  <Chart type="doughnut" :data="chartData" :options="lightOptions" class="max-w-max" />
  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
</template>

<script setup lang="ts">
import { ref } from "vue";

const chartData = ref({
  labels: ["Hardware", "Support", "Software"],
  datasets: [
    {
      data: [300, 50, 100],
      backgroundColor: ["#33B580", "#ADE1CC", "#EBF8F2"],
      hoverBackgroundColor: ["#33B580", "#ADE1CC", "#EBF8F2"],
    },
  ],
});

const lightOptions = ref({
  plugins: {
    legend: {
      labels: {
        color: "#495057",
      },
    },
  },
});
</script>
